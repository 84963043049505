import { Role } from '../classes/roles';
import { Module } from '../pages/homepage/classes/modules';

export const MainConstants = {
  hideHeaderTitles: ['Login.PageTitle', 'Login.ResetPassword'],
  headerOnlyTitles: ['Report.SpecialisationReport', 'Report.SpecialisationReportDetail'],
  refreshSkippedRoutes: [
    'i18n',
    'refresh',
    'login',
    'reset-password',
    'set-new-password',
    'specialisation-report',
  ],
  defaultPageSizeOptions: [5, 10, 25, 50, 100],
  defaultPageSize: 25,
  sortDisabledColumns: [
    'actions',
    'department',
    'headOfSpecialisation',
    'enName',
    'responsibleDepartment',
    'credit',
    'firstSemester',
    'subjectVersionName',
    'subjectVersionSemesterStatus',
    'site',
    'semester',
    'workSchedule',
    'status',
    'teacherStatus',
    'trainingLevel',
  ],
};

export const Routes = {
  home: '/home',
  login: '/login',
  curriculums: '/curriculums',
  subjects: '/subjects',
  semesters: '/semesters',
  departments: '/departments',
  administrators: '/administrators',
  resetPassword: '/reset-password',
  specialisations: '/specialisations',
  teacherStatuses: '/teacher-statuses',
  teachers: '/teachers',
  headOfSpecialisations: '/head-of-specialisations',
  departmentAdministrators: '/department-administrators',
  subjectVersionStatuses: '/subject-version-statuses',
  subjectResponsibles: '/subject-responsible-teachers',
  curriculumVersionStatuses: '/curriculum-version-statuses',
  subjectVersions: '/subject-versions',
  activityCategories: '/activity-categories',
  activityRates: '/activity-rates',
  requirementForms: '/requirement-forms',
  activityTypes: '/activity-types',
  subjectVersionSemesterStatuses: '/subject-version-semester-statuses',
  sites: '/sites',
  workSchedules: '/work-schedules',
  subjectVersionSemesters: '/subject-version-semesters',
  bugReports: '/bug-reports',
  reportBugs: '/report-bug',
  subjectInterdependencyMesh: '/subject-interdependency-mesh',
  users: '/users',
  specialisationReport: '/specialisation-report',
  trainingLanguages: '/training-languages',
  trainingLevels: '/training-levels',
};

export const Endpoints = {
  me: '/me',
  login: '/login',
  logout: '/logout',
  shibLogin: '/shibboleth-login',
  shibLogout: '/shibboleth-logout',
  curriculums: '/curriculum-versions',
  subjects: '/subjects',
  semesters: '/semesters',
  departments: '/departments',
  administrators: '/administrators',
  resetPassword: '/reset-password',
  setNewPassword: '/set-new-password',
  specialisations: '/specialisations',
  headOfSpecialisations: '/head-of-specialisations',
  teacherStatuses: '/teacher-statuses',
  teachers: '/teachers',
  users: '/users',
  departmentAdministrators: '/department-administrators',
  subjectVersionStatuses: '/subject-version-statuses',
  curriculumVersionStatuses: '/curriculum-version-statuses',
  subjectResponsibles: '/subject-responsible-teachers',
  subjectVersions: '/subject-versions',
  activityCategories: '/activity-categories',
  requirementForms: '/requirement-forms',
  activityRates: '/activity-rates',
  activityTypes: '/activity-types',
  subjectVersionSemesterStatuses: '/subject-version-semester-statuses',
  sites: '/sites',
  workSchedules: '/work-schedules',
  subjectVersionSemesters: '/subject-version-semesters',
  reportBugs: '/bug-reports',
  isCurrentHeadOfSpecialisation: 'is-current-head-of-specialisation',
  selectRole: '/select-role',
  specialisationReport: '/specialisation-report',
  trainingLevels: '/training-levels',
  trainingLanguages: '/training-languages',
};

export const Modules: Module[] = [
  {
    title: 'SubjectVersionSemester.SubjectVersionSemesters',
    url: Routes.subjectVersionSemesters,
    roles: [Role.Anyone],
    icon: 'apps',
  },
  {
    title: 'SubjectVersion.SubjectVersions',
    url: Routes.subjectVersions,
    roles: [Role.Anyone],
    icon: 'filter_1',
  },
  { title: 'Curriculum.Curriculums', url: Routes.curriculums, roles: [Role.Anyone], icon: 'insert_drive_file' },
  {
    title: 'Bug.ReportBug',
    url: Routes.reportBugs,
    roles: [Role.Anyone],
    icon: 'bug_report',
  },
];

export const AdminModules: Module[] = [
  {
    title: 'Subject.Subjects',
    url: Routes.subjects,
    roles: [Role.Admin, Role.HeadOfSpecialisation, Role.Employee],
    icon: 'library_books',
  },

  {
    title: 'Semester.Semesters',
    url: Routes.semesters,
    roles: [Role.Admin],
    icon: 'date_range',
  },
  {
    title: 'Department.Departments',
    url: Routes.departments,
    roles: [Role.Admin],
    icon: 'group_work',
  },
  {
    title: 'Administrator.Administrators',
    url: Routes.administrators,
    roles: [Role.Admin],
    icon: 'supervised_user_circle',
  },
  {
    title: 'Specialisation.Specialisations',
    url: Routes.specialisations,
    roles: [Role.Admin, Role.HeadOfSpecialisation],
    icon: 'view_list',
  },
  {
    title: 'TeacherStatus.TeacherStatuses',
    url: Routes.teacherStatuses,
    roles: [Role.Admin],
    icon: 'assignment_ind',
  },
  {
    title: 'Teacher.Teachers',
    url: Routes.teachers,
    roles: [Role.Admin],
    icon: 'people',
  },
  {
    title: 'Specialisation.HeadsOfSpecialisation',
    url: Routes.headOfSpecialisations,
    roles: [Role.Admin],
    icon: 'person_pin',
  },
  {
    title: 'Administrator.DepartmentAdministrators',
    url: Routes.departmentAdministrators,
    roles: [Role.Admin],
    icon: 'contacts',
  },
  {
    title: 'SubjectVersionStatus.SubjectVersionStatuses',
    url: Routes.subjectVersionStatuses,
    roles: [Role.Admin],
    icon: 'label_important',
  },
  {
    title: 'CurriculumVersionStatus.CurriculumVersionStatuses',
    url: Routes.curriculumVersionStatuses,
    roles: [Role.Admin],
    icon: 'label_important',
  },
  {
    title: 'SubjectResponsible.SubjectResponsibles',
    url: Routes.subjectResponsibles,
    roles: [Role.Admin, Role.DepartmentAdministrator],
    icon: 'assignment',
  },

  {
    title: 'ActivityCategories.ActivityCategories',
    url: Routes.activityCategories,
    roles: [Role.Admin],
    icon: 'category',
  },
  {
    title: 'RequirementForms.RequirementForms',
    url: Routes.requirementForms,
    roles: [Role.Admin],
    icon: 'rate_review',
  },
  {
    title: 'SubjectVersionSemesterStatuses.SubjectVersionSemesterStatuses',
    url: Routes.subjectVersionSemesterStatuses,
    roles: [Role.Admin],
    icon: 'label_important',
  },
  {
    title: 'ActivityRates.ActivityRates',
    url: Routes.activityRates,
    roles: [Role.Admin],
    icon: 'trending_up',
  },
  {
    title: 'ActivityTypes.ActivityTypes',
    url: Routes.activityTypes,
    roles: [Role.Admin],
    icon: 'apps',
  },
  {
    title: 'Sites.Sites',
    url: Routes.sites,
    roles: [Role.Admin],
    icon: 'location_on',
  },
  {
    title: 'WorkSchedule.WorkSchedules',
    url: Routes.workSchedules,
    roles: [Role.Admin],
    icon: 'list_alt',
  },
  {
    title: 'Bug.BugReports',
    url: Routes.bugReports,
    roles: [Role.Admin],
    icon: 'warning',
  },
  {
    title: 'User.Users',
    url: Routes.users,
    roles: [Role.Admin],
    icon: 'people',
  },
  {
    title: 'TrainingLevel.TrainingLevels',
    url: Routes.trainingLevels,
    roles: [Role.Admin],
    icon: 'list_alt',
  },
  {
    title: 'TrainingLanguage.TrainingLanguages',
    url: Routes.trainingLanguages,
    roles: [Role.Admin],
    icon: 'language',
  },
];

export const ErrorMessages: Record<string, string> = {
  /* eslint-disable @typescript-eslint/naming-convention */
  NameTaken: 'Common.Error.NameTaken',
  'Teacher.CannotDelete': 'Teacher.CannotDelete',
  'Subject.CannotDelete': 'Subject.CannotDelete',
  'SubjectVersion.CannotDelete': 'SubjectVersion.CannotDelete',
  'SubjectResponsibleTeacher.CannotDelete': 'SubjectResponsible.CannotDelete',
  'Teacher.NeptunCodeTaken': 'Teacher.NeptunCodeTaken',
  'Subject.NeptunCodeTaken': 'Subject.NeptunCodeTaken',
  'Specialisation.CodeTaken': 'Specialisation.CodeTaken',
  'SubjectVersionStatus.NameTaken': 'SubjectVersionStatus.NameTaken',
  'Specialisation.CannotDelete': 'Specialisation.CannotDelete',
  'HeadOfSpecialisation.CannotDelete': 'Specialisation.Head.CannotDelete',
  'HeadOfSpecialisation.DatesAlreadyAssignedToTheSpecifiedSpecialisation':
    'Specialisation.DatesAlreadyAssignedToTheSpecifiedSpecialisation',
  'SubjectResponsibleTeacher.DatesAlreadyAssignedToTheSpecifiedSubject':
    'SubjectResponsible.DatesAlreadyAssignedToTheSpecifiedSubject',
  'SubjectVersionSemester.NoNextSemester': 'SubjectVersionSemester.NoNextSemester',
  'SubjectVersionSemester.NoPermission': 'Common.NoPermission',
  'SubjectVersionSemester.ShouldBeUnique': 'SubjectVersionSemester.DuplicateShouldBeUnique',
  'User.CannotDelete': 'User.CannotDelete',
  'SubjectVersionSemester.CannotExportDueToInvalidStatusOrMissingSemester':
    'SubjectVersionSemester.CannotExportDueToInvalidStatusOrMissingSemester',
};

export const GeneralError: string = 'Common.Error.GeneralError';
